/* eslint-disable */
import orderBy from 'lodash/orderBy';
import i18n from 'i18n';

import { formatUserDisplayName } from 'utils/formatters/user';
import { getOffset, getDuration } from 'utils/formatters/escalations';
import { JOB_STATUS } from 'utils/constants';
import dayjs from 'dayjs';
import moment from 'moment';

const _getStatuses = (_) => ({
  queued: { id: 'queued', displayName: i18n.t('jobs:Queued') },
  pending: { id: 'pending', displayName: i18n.t('jobs:Not Started') },
  in_progress: { id: 'in_progress', displayName: i18n.t('jobs:In Progress') },
  completed: { id: 'completed', displayName: i18n.t('common:Done') },
  cancelled: { id: 'cancelled', displayName: i18n.t('jobs:Cancelled') },
  on_hold: { id: 'on_hold', displayName: i18n.t('jobs:On Hold') },
});

const _getNotificationStatuses = (_) => ({
  pending: { id: 'pending', displayName: i18n.t('jobs:Not Started') },
  in_progress: { id: 'in progress', displayName: i18n.t('jobs:In Progress') },
  completed: { id: 'completed', displayName: i18n.t('common:Done') },
  cancelled: { id: 'cancelled', displayName: i18n.t('jobs:Cancelled') },
  on_hold: { id: 'on hold', displayName: i18n.t('jobs:On Hold') },
});

const _getJobStatusOptions = (_) => ({
  not_started: { id: 'not_started', displayName: i18n.t('jobs:Not Started') },
  in_progress: { id: 'in_progress', displayName: i18n.t('jobs:In Progress') },
  completed: { id: 'completed', displayName: i18n.t('common:Done') },
  cancelled: { id: 'cancelled', displayName: i18n.t('jobs:Cancelled') },
  on_hold: { id: 'on_hold', displayName: i18n.t('jobs:On Hold') },
});

const _getAdvancedStatuses = (_) => ({
  new: { id: 'new', displayName: i18n.t('jobs:Not Started') },
  archived: { id: 'archived', displayName: i18n.t('common:Done') },
});

const _getPriorities = (_) => ({
  highest: { id: 'highest', displayName: i18n.t('jobs:Highest') },
  high: { id: 'high', displayName: i18n.t('jobs:High') },
  medium: { id: 'medium', displayName: i18n.t('jobs:Medium') },
  low: { id: 'low', displayName: i18n.t('jobs:Low') },
});

const _getActions = (_) => ({
  clean: { id: 'clean', displayName: i18n.t('jobs:Clean') },
  deliver: { id: 'deliver', displayName: i18n.t('jobs:Deliver') },
  remove: { id: 'remove', displayName: i18n.t('jobs:Remove') },
  repair: { id: 'repair', displayName: i18n.t('jobs:Repair') },
  replace: { id: 'replace', displayName: i18n.t('jobs:Replace') },
  inspect: { id: 'inspect', displayName: i18n.t('jobs:Inspect') },
  install: { id: 'install', displayName: i18n.t('jobs:Install') },
  perform: { id: 'perform', displayName: i18n.t('jobs:Perform') },
  test: { id: 'test', displayName: i18n.t('jobs:Test') },
  noJobAction: { id: 'noJobAction', displayName: i18n.t('jobs:No Job Action') },
});

const _getJobTypes = (_) => ({
  internal: i18n.t('jobs:Internal Request'),
  guest: i18n.t('jobs:Guest Request'),
});

const _getAuditActions = (_) => ({
  edited: i18n.t('jobs:Updated by'),
  deleted: i18n.t('jobs:Deleted by'),
  deletedNote: i18n.t('jobs:Note deleted by'),
  taken: i18n.t('jobs:Taken by'),
  assigned: i18n.t('jobs:Assigned by'),
  started: i18n.t('jobs:Started by'),
  paused: i18n.t('jobs:Paused by'),
  completed: i18n.t('jobs:Completed by'),
  cancelled: i18n.t('jobs:Cancelled by'),
  archived: i18n.t('jobs:Deleted by'),
  created: i18n.t('common:Added by'),
  added: i18n.t('common:Added by'),
  reopened: i18n.t('jobs:Reopened by'),
  assignedTo: i18n.t('jobs:Assigned to'),
  disabled: i18n.t('jobs:Could Not Assign'),
  escalated: i18n.t('jobs:Escalated to'),
});

const _auditActions = {
  edited: 'Updated by',
  deleted: 'Deleted by',
  deletedNote: 'Note deleted by',
  taken: 'Taken by',
  assigned: 'Assigned by',
  started: 'Started by',
  paused: 'Paused by',
  completed: 'Completed by',
  cancelled: 'Cancelled by',
  archived: 'Deleted by',
  created: 'Added by',
  added: 'Added by',
  reopened: 'Reopened by',
  assignedTo: 'Assigned to',
  disabled: 'Could Not Assign',
  escalated: 'Escalated to',
};

const _getCommonTranslations = (_) => ({
  by: i18n.t('common:by'),
});

export const translatedData = {};

function updateTranslations() {
  translatedData._priorities = _getPriorities();
  translatedData.priorities = Object.values(_getPriorities());
  translatedData._actions = _getActions();
  translatedData.actions = Object.values(_getActions());
  translatedData._statuses = _getStatuses();
  translatedData.jobStatusOptions = Object.values(_getJobStatusOptions());
  translatedData._advancedStatuses = _getAdvancedStatuses();
  translatedData.statuses = Object.values(_getStatuses());
  translatedData.notificationStatuses = Object.values(
    _getNotificationStatuses(),
  );
  translatedData.jobTypes = _getJobTypes();
  translatedData._auditActions = _getAuditActions();
  translatedData.common = _getCommonTranslations();
}

// i18next seems ready -> initial fill translations
if (i18n.isInitialized) {
  updateTranslations();
}

// reset translations to new values on language change
i18n.on('languageChanged', (lng) => {
  updateTranslations();
});

// we loaded some translation file? reset needed?!?
// https://www.i18next.com/overview/api#store-events
i18n.on('loaded', (lng) => {
  updateTranslations();
});

export function getType(job) {
  return translatedData.jobTypes[job?.type];
}

export const { statuses } = translatedData;
export const addJobStatusOptions = translatedData._getAddJobStatuses;
export function getStatus(job) {
  const statusMap = {
    ...translatedData._statuses,
    ...translatedData._advancedStatuses,
  };
  return statusMap[job.status];
}

export const { priorities } = translatedData;
export function getPriority(job) {
  return translatedData._priorities[job.priority];
}

const priorityLevel = { highest: 0, high: 1, medium: 2, low: 3 };

export function sortByPriorityAndDoBy(jobs) {
  return orderBy(jobs, [(job) => [priorityLevel[job.priority]], 'doBy']);
}

const _actions = {
  clean: { id: 'clean', displayName: 'Clean' },
  deliver: { id: 'deliver', displayName: 'Deliver' },
  remove: { id: 'remove', displayName: 'Remove' },
  repair: { id: 'repair', displayName: 'Repair' },
  replace: { id: 'replace', displayName: 'Replace' },
  inspect: { id: 'inspect', displayName: 'Inspect' },
  install: { id: 'install', displayName: 'Install' },
  perform: { id: 'perform', displayName: 'Perform' },
  test: { id: 'test', displayName: 'Test' },
  noJobAction: { id: 'noJobAction', displayName: 'No Job Action' },
  departure: { id: 'departure', displayName: 'Departure' },
  stayover: { id: 'stayover', displayName: 'Stayover' },
  inspection: { id: 'inspection', displayName: 'Inspection' },
  check: { id: 'check', displayName: 'Check' },
  touchup: { id: 'touchup', displayName: 'Touch-up' },
  turndown: { id: 'turndown', displayName: 'Turndown' },
  correction: { id: 'correction', displayName: 'Correction' },
};

export const actions = Object.values(_actions);

export function getAction(action, isTranslated = false, isJobItem = false) {
  const actions = isTranslated ? translatedData._actions : _actions;
  if (action === 'noJobAction' && isJobItem) return actions.noJobAction;

  if (!action && isJobItem) return actions.noJobAction;

  if (!action) return '';

  return actions[!action ? 'noJobAction' : action?.toLowerCase()];
}

function getItems(items, joiner = ', ') {
  let out = '';
  if (!items) return out;
  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    out += item.name;
    if (item.amount > 1) out += ` (${item.amount})`;
    if (i + 1 < items.length) out += joiner;
  }

  return out;
}

export function getName(job, isTranslated = false) {
  const showAction = !job.parentChecklistTaskId;
  const action = getAction(job.action, isTranslated);
  const actionDisplayName = (action?.displayName);

  return {
    short: `#${job.id}`,
    medium: `${getPriority(job).displayName} | ${getLocations(job)} : ${
      getAction(job.action, isTranslated) && showAction
        ? actionDisplayName
        : ''
    } ${getItems(job.items)}`,
    long: `#${job.id} ${
      getAction(job.action, isTranslated) && showAction
        ? actionDisplayName
        : ''
    } ${getItems(job.items)}`,
    longer: `${getLocations(job)} : ${
      getAction(job.action, isTranslated) && showAction
        ? actionDisplayName
        : ''
    } ${getItems(job.items)}`,
    longest: `#${job.id} | ${getLocations(job)} : ${
      getAction(job.action, isTranslated) && showAction
        ? actionDisplayName
        : ''
    } ${getItems(job.items)}`,
    longestWithoutId: `${getLocations(job)}: ${
      getAction(job.action, isTranslated) && showAction
        ? actionDisplayName
        : ''
    } ${getItems(job.items)}`,
  };
}

function getMappedLocationsName({showLong}) {
  return (location) => {
    const locationDisplayName = (showLong ? location.longDisplayName : location.shortDisplayName);

    return locationDisplayName || location.name;
  }
}

export function getLocations(job, showLong = false) {
  if (job?.location) return job.location;

  const locations = job.locations
    ? job.locations
        .map(getMappedLocationsName({showLong}))
        .join(', ')
    : job.location &&
      JSON.parse(job.location)
        .map(getMappedLocationsName({showLong}))
        .join(', '); // Need this tertiary operation since push notifications use "location" not "locations"
  return locations;
}

export function getDescription(job, isTranslated = false, showLong = false) {
  const showAction = !job.parentChecklistTaskId;
  const actions = isTranslated ? translatedData._actions : _actions;
  let items;

  try {
    const parsedItems = JSON.parse(job?.items);

    items = getItems(parsedItems);
  } catch (error) {
    items = job?.item;
  }
  return `${getLocations(job, showLong)}: ${
    actions[job.action?.toLowerCase()] && showAction
      ? actions[job.action?.toLowerCase()].displayName
      : ''
  } ${items}`;
}

export function getActions() {
  return translatedData._actions;
}

export function getTeamMember(assignee, t = (_) => _) {
  if (!assignee) return '';

  if (assignee.id === '-88')
    return {
      short: t('jobs:Auto A'),
      long: t('jobs:Auto Assign'),
    };

  return {
    short: `${assignee.firstName} ${assignee.lastName[0]}`,
    long: `${assignee.firstName} ${assignee.lastName}`,
  };
}

function namesAreTheSame(audit) {
  if (!audit.details) {
    return false;
  }
  return formatUserDisplayName(audit.employee) === audit.details;
}

export function getAuditAction(audit, t = false) {
  if (audit.action === 'taken') {
    return formatTakenAuditAction(audit, t);
  }

  if (audit.action === 'escalated') {
    return formatEscalationAuditAction(audit, t);
  }

  if (audit.action === 'escalated-v2') {
    return audit.details;
  }

  if (audit.action === 'converted')
    return i18n.t('jobs:Converted {{task}} to Job {{id}}', {
      task: audit.modifier1,
      id: audit.details,
    });

  if (audit.action === 'edited' && audit.object === 'JobNote')
    return i18n.t('jobs:{{note}} Edited by {{user}}', {
      user: formatUserDisplayName(audit.employee),
      note: audit.details,
    });

  return formatDefaultAuditAction(audit, t);
}

function getFormatedModifier(modifier1) {
  if (modifier1 === 'ERROR') {
    return i18n.t('jobs:Task Fail');
  }
  if (modifier1 === '') {
    return i18n.t('jobs:Task Pass');
  }

  return '';
}

function formatDefaultAuditAction(audit, isTranslated) {
  const auditActions = isTranslated
    ? translatedData._auditActions
    : _auditActions;

  const deletedNote = audit.action === 'deleted' && audit.object === 'JobNote';
  const defaultAuditActivityLabel =
    auditActions[audit.action] || auditActions.edited;
  const auditActivityLabelHasModifier =
    typeof audit.modifier1 === 'string' && audit.action !== 'deleted';

  const auditActivityLabelWithModifier = getFormatedModifier(audit.modifier1);
  const translatedAuditActivityLabel = deletedNote
    ? auditActions.deletedNote
    : defaultAuditActivityLabel;
  const auditLabel = auditActivityLabelHasModifier
    ? auditActivityLabelWithModifier
    : translatedAuditActivityLabel;
  const auditDetails = audit.details && audit.details.split(' ').join(' ');
  const auditPrefix = audit.details ? auditDetails : '';
  const auditDiv = audit.object === 'ChecklistTask' ? ' - ' : '';
  const auditUserName =
    audit.action === 'disabled' ? '' : formatUserDisplayName(audit.employee);

  return `${auditPrefix} ${auditLabel} ${auditDiv} ${auditUserName}`;
}

function formatTakenAuditAction(audit, isTranslated) {
  const auditActions = isTranslated
    ? translatedData._auditActions
    : _auditActions;
  if (namesAreTheSame(audit)) {
    return `${auditActions.taken} ${formatUserDisplayName(audit.employee)}`;
  }
  return `${auditActions.assignedTo} ${audit.details} ${
    isTranslated ? translatedData.common.by : 'by'
  } ${formatUserDisplayName(audit.employee)}`;
}

function formatEscalationAuditAction(audit, t) {
  const auditActions = t ? translatedData._auditActions : _auditActions;
  const offSetType = audit.details;
  const timeOffset = audit.modifier1;
  const assignedType = audit.modifier2;
  const role = audit.object.split(',').join(', ');
  const filler = t('common:since job is');

  if (assignedType === null) {
    return `${auditActions.escalated} ${capitalizeEachWord(
      role,
    )} ${filler} ${getDuration(timeOffset)} ${getOffset(offSetType)}`;
  }
  return `${auditActions.escalated} ${capitalizeEachWord(
    role,
  )} ${filler} ${assignedType} ${t('common:and is')} ${getDuration(
    timeOffset,
  )} ${getOffset(offSetType)}`;
}

export const capitalizeEachWord = (sentence) =>
  sentence.replace(/\b(\w)/g, (letter) => letter.toUpperCase());

export function generateOpenJobUrl(jobId, propertyId) {
  return `${window.location.origin}/jobs?openJob=${jobId}&propertyId=${propertyId}`;
}

export function getJobAssignedPushNotificationTitle(data, t) {
  return `${t('common:You have a new job')} ${getDescription(
    data,
    false,
    true,
  )}`;
}

export function getJobAssignedPushNotificationBody(data, t) {
  return t(`common:Due by {{dateTime}}`, {
    dateTime: moment.unix(data.dueBy).tz(data.timeZone).format('l [at] LTS'),
  });
}

// used for job card
export function getLatestNote(job) {
  const { notes } = job;
  if (!notes || notes.length < 1) return '';
  return [...notes].sort((a, b) => b.time - a.time)[0].text;
}
export function getDueTime(doBy) {
  if (dayjs.isDayjs(doBy))
    return moment(doBy.locale('en').format('lll')).format('l LT');
  return doBy && doBy.format('l LT');
}
export function isDone(job) {
  return [JOB_STATUS.completed, JOB_STATUS.cancelled].includes(job.status);
}
export function isOverdue(job, dayJsTime, timezone) {
  const { doBy } = job;
  const now = moment().unix();

  if (isDone(job)) return false;

  return doBy < now;
}
export const getTooltipPosition = (job, index) => {
  if (index === 0) {
    if (job.status === JOB_STATUS.on_hold) {
      return 'bottom right';
    }
    return 'bottom left';
  }
  if (job.status === JOB_STATUS.on_hold) {
    return 'top right';
  }
  return 'top left';
};
